import type { GridColumns } from "@mui/x-data-grid"
import { dateTimeColumnDefaultOptions } from "utils/columns"
import { DATE_FORMATS, formatDate } from "utils/date"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import type {
  Review,
  ReviewStatus,
  VelocityIncident,
  VelocityIncidentTransaction,
} from "api/endpoints"
import { formatToCurrency } from "utils/formatToCurrency"

export const useIncidentColumns = (
  handleResolveIncident: (
    payment_velocity_incident_id: string,
    reviews: { internal_transaction_id: string }[],
    status: ReviewStatus
  ) => void,
  handleReviewTransaction: (
    internal_transaction_id: string,
    payment_velocity_incident_id: string,
    status: ReviewStatus
  ) => void
): GridColumns<VelocityIncident> => [
  {
    field: "payment_velocity_incident_id",
    headerName: "Incident ID",
    width: 120,
  },
  {
    field: "entity_id",
    headerName: "Entity ID",
    width: 180,
    renderCell: (params) => (
      <Typography
        sx={{
          wordBreak: "break-word",
          overflowWrap: "break-word",
          maxWidth: "100%",
        }}
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: "started_at",
    headerName: "Started At",
    width: 150,
    ...dateTimeColumnDefaultOptions(),
  },
  {
    field: "resolved_at",
    headerName: "Resolved At",
    width: 150,
    renderCell: (params) =>
      params.value
        ? formatDate(params.value, DATE_FORMATS.standardShortDateTime)
        : "-",
  },
  {
    field: "control",
    headerName: "Control Details",
    width: 200,
    renderCell: (params) => (
      <Stack spacing={1}>
        <Typography variant="body2">
          Operation: {params.row.operation || "ALL OPERATIONS"}
        </Typography>
        <Typography variant="body2">Type: {params.row.type}</Typography>
        <Typography variant="body2">Period: {params.row.period}</Typography>
        <Typography variant="body2">
          Upper Limit: {params.row.upper_limit}
        </Typography>
        {params.row.aggregate_threshold && (
          <Typography variant="body2">
            Aggregate: {params.row.aggregate_threshold}
          </Typography>
        )}
      </Stack>
    ),
  },
  {
    field: "transactions",
    headerName: "Transactions",
    width: 300,
    renderCell: (params) => (
      <Stack spacing={1}>
        {params.row.reviews.map(
          (
            review: {
              transaction: VelocityIncidentTransaction
              review: Review
            },
            index: number
          ) => (
            <Paper key={index} variant="outlined" sx={{ p: 1 }}>
              <Typography variant="body2">
                ID: {review.transaction.internal_transaction_id}
              </Typography>
              <Typography variant="body2">
                Amount:{" "}
                {formatToCurrency(
                  review.transaction.amount,
                  review.transaction.currency,
                  true
                )}
              </Typography>
              <Typography variant="body2">
                Time:{" "}
                {formatDate(
                  review.transaction.timestamp,
                  DATE_FORMATS.standardShortDateTime
                )}
              </Typography>
              <Typography
                variant="body2"
                color={
                  review.review.status === "APPROVED"
                    ? "success.main"
                    : review.review.status === "REJECTED"
                    ? "error.main"
                    : "warning.main"
                }
              >
                Status: {review.review.status}
              </Typography>
              {review.review.status === "PENDING" && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleReviewTransaction(
                        review.transaction.internal_transaction_id,
                        params.row.payment_velocity_incident_id,
                        "APPROVED"
                      )
                    }
                    sx={{ mr: 1, mt: 1 }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleReviewTransaction(
                        review.transaction.internal_transaction_id,
                        params.row.payment_velocity_incident_id,
                        "REJECTED"
                      )
                    }
                    sx={{ mt: 1 }}
                  >
                    Reject
                  </Button>
                </>
              )}
            </Paper>
          )
        )}
      </Stack>
    ),
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params) => {
      if (params.row.resolved_at) {
        return <Typography variant="body2">Resolved</Typography>
      }

      return (
        <Stack spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleResolveIncident(
                params.row.payment_velocity_incident_id,
                params.row.reviews
                  .filter((review) => review.review.status === "PENDING")
                  .map((review) => ({
                    internal_transaction_id:
                      review.transaction.internal_transaction_id,
                  })),
                "APPROVED"
              )
            }
          >
            Approve Pending Transactions And Resolve Incident
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() =>
              handleResolveIncident(
                params.row.payment_velocity_incident_id,
                params.row.reviews
                  .filter((review) => review.review.status === "PENDING")
                  .map((review) => ({
                    internal_transaction_id:
                      review.transaction.internal_transaction_id,
                  })),
                "REJECTED"
              )
            }
          >
            Reject Pending Transactions And Resolve Incident
          </Button>
        </Stack>
      )
    },
  },
]
