import { useQuery, type UseQueryResult } from "@tanstack/react-query"
import type { TransactionReviewsResult } from "api/endpoints"
import { endpoints } from "api"
import { type AxiosResponse } from "axios"

const getQueryKey = () => ["paymentVelocityControlReviews"]

export const usePaymentVelocityControlReviews = (
  showCompleted: boolean
): UseQueryResult<TransactionReviewsResult> => {
  const endpoint = endpoints.paymentVelocityControls.getTransactionsReviews(
    showCompleted ? undefined : "PENDING"
  )

  return useQuery<
    AxiosResponse<{ data: TransactionReviewsResult }>,
    Error,
    TransactionReviewsResult
  >([...getQueryKey(), showCompleted], endpoint.get, {
    select: (response) => response.data.data,
  })
}

export default usePaymentVelocityControlReviews
