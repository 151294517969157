import { useQuery, type UseQueryResult } from "@tanstack/react-query"
import type { VelocityIncidentsResult } from "api/endpoints"
import { endpoints } from "api"
import { type AxiosResponse } from "axios"

const getQueryKey = () => ["paymentVelocityControlIncidents"]

export const usePaymentVelocityControlIncidents = (
  showResolved: boolean
): UseQueryResult<VelocityIncidentsResult> => {
  const endpoint =
    endpoints.paymentVelocityControls.getIncidentsReviews(showResolved)

  return useQuery<
    AxiosResponse<{ data: VelocityIncidentsResult }>,
    Error,
    VelocityIncidentsResult
  >([...getQueryKey(), showResolved], endpoint.get, {
    select: (response) => response.data.data,
  })
}

export default usePaymentVelocityControlIncidents
