import type { ReactElement } from "react"
import { useMemo } from "react"
import AcUnit from "@mui/icons-material/AcUnit"
import AccountBalance from "@mui/icons-material/AccountBalance"
import AdUnits from "@mui/icons-material/AdUnits"
import AssessmentIcon from "@mui/icons-material/Assessment"
import HelpCenter from "@mui/icons-material/HelpCenter"
import Announcement from "@mui/icons-material/Announcement"
import AddBusiness from "@mui/icons-material/AddBusiness"
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings"
import AppRegistration from "@mui/icons-material/AppRegistration"
import BugReport from "@mui/icons-material/BugReport"
import Business from "@mui/icons-material/Business"
import CalendarMonth from "@mui/icons-material/CalendarMonth"
import Campaign from "@mui/icons-material/Campaign"
import Groups from "@mui/icons-material/Groups"
import TransformIcon from "@mui/icons-material/Transform"
import HistoryEdu from "@mui/icons-material/HistoryEdu"
import ImportContacts from "@mui/icons-material/ImportContacts"
import InsightsIcon from "@mui/icons-material/Insights"
import MailOutline from "@mui/icons-material/MailOutline"
import Paid from "@mui/icons-material/Paid"
import LockOpen from "@mui/icons-material/LockOpen"
import QueryBuilder from "@mui/icons-material/QueryBuilder"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong"
import SickIcon from "@mui/icons-material/Sick"
import SwitchLeft from "@mui/icons-material/SwitchLeft"
import Category from "@mui/icons-material/Category"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import AttachMoney from "@mui/icons-material/AttachMoney"
import AddBox from "@mui/icons-material/AddBox"
import PendingActionsIcon from "@mui/icons-material/PendingActions"
import InstallMobileIcon from "@mui/icons-material/InstallMobile"
import PercentIcon from "@mui/icons-material/Percent"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import CachedIcon from "@mui/icons-material/Cached"
import FindInPage from "@mui/icons-material/FindInPage"
import { FactCheck, VerifiedUser } from "@mui/icons-material"
import ChatIcon from "@mui/icons-material/Chat"
import CreditIcon from "@mui/icons-material/CardMembership"
import { usePinnedItems } from "./NavItem"

const BUILD_REGION = process.env.REACT_APP_BUILD_REGION
const LOCALE = Symbol("country where app is used (e.g. us, uk)")

const titleCompare = (a: { title: string }, b: { title: string }) =>
  a.title.localeCompare(b.title)

interface NavigationConfigSection {
  subheader: string
  items: {
    title: string
    path: string
    [LOCALE]?: string
    target?: string
    icon?: ReactElement
    children?: {
      title: string
      path: string
      target?: string
    }[]
  }[]
}

type NavigationConfig = NavigationConfigSection[]

const NAVIGATION_CONFIG: NavigationConfig = [
  {
    subheader: "General",
    items: [
      {
        title: "App Releases",
        path: "/app-releases",
        [LOCALE]: "uk",
        icon: <InstallMobileIcon />,
      },
      {
        title: "Support Conversations",
        path: "/support-conversations",
        [LOCALE]: "uk",
        icon: <ChatIcon />,
      },
      {
        title: "Root Actions",
        path: "/root-actions",
        icon: <PendingActionsIcon />,
      },
      {
        title: "Transaction Screenings",
        path: "/transaction-screenings",
        icon: <FactCheck />,
      },
      {
        title: "Payment Velocity Controls",
        path: "/payment-velocity-controls",
        icon: <FactCheck />,
        [LOCALE]: "uk",
      },
      {
        title: "Client Book Monitoring",
        path: "/client-book-monitoring/strategic-review",
        icon: <AssessmentIcon />,
        [LOCALE]: "uk",
      },
      {
        title: "Direct Debit Collections",
        path: "/dd-collections",
        icon: <AccountBalanceIcon />,
        [LOCALE]: "uk",
      },
      {
        title: "Allow List",
        path: "/data/allow_list",
        icon: <LockOpen />,
      },
      {
        title: "Change Employee States",
        path: "/data/change_employee_states",
        icon: <SwitchLeft />,
      },
      {
        title: "Corporate Groups",
        path: "/data/corporate_groups",
        icon: <Groups />,
      },
      {
        title: "Companies",
        path: "/data/companies",
        icon: <Business />,
      },
      {
        title: "Enrollment Issues",
        path: "/data/enrollment_issues",
        icon: <BugReport />,
      },
      {
        title: "Expenses",
        path: "/data/expenses",
        icon: <Paid />,
      },
      {
        title: "Feature Flags",
        path: "/data/feature_flags",
        icon: <AutoFixHighIcon />,
      },
      {
        title: "Insurance",
        path: "/insurance/policies",
        icon: <SickIcon />,
        [LOCALE]: "uk",
      },
      {
        title: "Credit",
        path: "/credit",
        icon: <CreditIcon />,
        [LOCALE]: "uk",
      },
      {
        title: "Job Roles",
        path: "/data/job-roles",
        icon: <Category />,
      },
      {
        title: "Kill Hung NHS Bot jobs",
        path: "/data/kill_hung_bot_jobs",
        icon: <AcUnit />,
        [LOCALE]: "uk",
      },
      {
        title: "Lookup Ledger",
        path: "/banking/lookup-ledger",
        icon: <FindInPage />,
      },
      {
        title: "Ledger Details",
        path: "/banking",
        icon: <ReceiptLongIcon />,
      },
      {
        title: "Pay Schedules",
        path: "/data/payschedules",
        icon: <CalendarMonth />,
      },
      {
        title: "Payment Sources",
        path: "/data/payment_sources",
        icon: <AccountBalance />,
      },
      {
        title: "Off cycle payments (US)",
        path: "/data/off-cycle-payments",
        icon: <CachedIcon />,
        [LOCALE]: "us",
      },
      {
        title: "Registrations",
        path: "/data/registrations",
        icon: <AppRegistration />,
      },
      {
        title: "Roots",
        path: "/data/rootusers",
        icon: <AdminPanelSettings />,
      },
      {
        title: "RCT Analysis",
        path: "/data/rcta",
        icon: <InsightsIcon />,
      },
      {
        title: "Contacts",
        path: "/data/contacts",
        icon: <ImportContacts />,
        [LOCALE]: "uk",
      },
      {
        title: "Overpayments",
        path: "/data/overpayments",
        icon: <Paid />,
        [LOCALE]: "uk",
      },
      {
        title: "Company Integration Transforms",
        path: "/data/companyintegrationtransforms",
        icon: <TransformIcon />,
        [LOCALE]: "uk",
      },
      {
        title: "Due Diligence Review",
        path: "/due-diligence-review",
        icon: <VerifiedUser />,
        [LOCALE]: "uk",
      },
    ],
  },
  {
    subheader: "Content",
    items: [
      {
        title: "Dialogs",
        path: "/data/dialogs",
        icon: <AdUnits />,
      },
      {
        title: "Discounts",
        path: "/data/discounts/retailers",
        icon: <PercentIcon />,
      },
      {
        title: "Multimedia Campaigns (US)",
        path: "/multimedia-campaigns",
        icon: <Campaign />,
        [LOCALE]: "us",
      },
      {
        title: "Email Campaigns",
        path: "/campaigns",
        icon: <Campaign />,
      },
      {
        title: "Email Templates",
        path: "/data/campaign_emails",
        icon: <MailOutline />,
      },
      {
        title: "Global Alert",
        path: "/data/global-alert",
        icon: <Announcement />,
      },
      {
        title: "Help Center",
        path: "/data/help-center",
        icon: <HelpCenter />,
      },
      {
        title: "Message NHS Ambassadors",
        path: "/data/nhs-ambassadors",
        icon: <Announcement />,
      },
    ],
  },
  {
    subheader: "Other",
    items: [
      {
        title: "Add Company",
        path: "/newco",
        icon: <AddBusiness />,
      },
      {
        title: "Cronjobs",
        path: "/crontab",
        icon: <QueryBuilder />,
      },
      {
        title: "Documentation",
        path: "/data/documentation",
        icon: <HistoryEdu />,
      },
      {
        title: "ACH Debit",
        path: "/ach_debit",
        icon: <AttachMoney />,
        [LOCALE]: "us",
      },
      {
        title: "New B2B Company",
        path: "/register-company",
        icon: <AddBox />,
      },
    ],
  },
].map((section) => ({
  ...section,
  items: section.items.sort(titleCompare),
}))
export let NAVIGATION_CONFIG_LOCALIZED: NavigationConfig

export function useNavigationConfig(): NavigationConfig {
  const [menuPins] = usePinnedItems()

  return useMemo(() => {
    const hasPins = Object.values(menuPins).some((value) => !!value)
    const _NAVIGATION_CONFIG_LOCALIZED =
      NAVIGATION_CONFIG.reduce<NavigationConfig>((sections, section) => {
        const localized_section_items = section.items.filter((item) => {
          return item[LOCALE] ? item[LOCALE] === BUILD_REGION : true
        })
        const localized_section = { ...section, items: localized_section_items }
        return [
          {
            ...localized_section,
          },
          ...sections,
        ]
      }, [])
    NAVIGATION_CONFIG_LOCALIZED = _NAVIGATION_CONFIG_LOCALIZED

    if (!hasPins) {
      return _NAVIGATION_CONFIG_LOCALIZED
    }

    const configWithPins =
      _NAVIGATION_CONFIG_LOCALIZED.reduce<NavigationConfig>(
        ([pinnedItems, ...config], section) => {
          const pinned = section.items.filter((item) => menuPins[item.title])
          const notPinned = section.items.filter(
            (item) => !menuPins[item.title]
          )

          return [
            {
              ...pinnedItems,
              items: [...pinnedItems.items, ...pinned].sort(titleCompare),
            },
            ...config,
            { ...section, items: notPinned },
          ]
        },
        [{ subheader: "Pinned", items: [] }]
      )

    return configWithPins
  }, [menuPins])
}
