import { Page } from "components/Page/Page"
import { TabNav } from "components"
import { Outlet, useLocation } from "react-router-dom"
import { routes } from "routes"

export default function PaymentVelocityControls() {
  const { pathname } = useLocation()

  const TABS = [
    {
      label: "Transactions Queue",
      href: routes.paymentVelocityControlsQueue(),
    },
    {
      label: "Incidents Queue",
      href: routes.paymentVelocityIncidentsQueue(),
    },
  ]

  return (
    <Page
      headerTitle="Payment Velocity Controls"
      tabs={<TabNav tabs={TABS} currentTab={pathname} />}
      scrollToTopButton
    >
      <Outlet />
    </Page>
  )
}
