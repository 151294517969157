import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import { type UpdateIncidentReviewPayload } from "api/endpoints"
import type { AxiosError } from "axios"
import { useNotification } from "context/notification"

export default function useUpdateIncidentReview(
  options?: Partial<{
    onSuccess: () => void
  }>
) {
  const queryClient = useQueryClient()
  const notify = useNotification()

  return useMutation(
    (payload: UpdateIncidentReviewPayload) => {
      const endpoint = endpoints.paymentVelocityControls.updateIncidentReview(
        payload.payment_velocity_incident_id
      )
      return endpoint.patch(payload)
    },
    {
      onSuccess: () => {
        notify.success("Incident review updated successfully")
        options?.onSuccess?.()
        queryClient.invalidateQueries(["paymentVelocityControlIncidents"])
        queryClient.invalidateQueries(["paymentVelocityControlReviews"])
      },
      onError: (e: AxiosError) => {
        const data = e?.response?.data as { message: string }
        notify.error(`Error updating incident review: ${data?.message}`)
      },
    }
  )
}
