import type { GridColumns } from "@mui/x-data-grid"
import {
  currencyColumnDefaultOptions,
  dateTimeColumnDefaultOptions,
} from "utils/columns"
import { DATE_FORMATS, formatDate } from "utils/date"
import type {
  ReviewStatus,
  TransactionReview,
  VelocityCheck,
} from "api/endpoints"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"

export const useColumns = (
  handleReview: (
    internal_transaction_id: string,
    velocity_checks: VelocityCheck[],
    status: ReviewStatus
  ) => void
): GridColumns<TransactionReview> => [
  {
    field: "internal_transaction_id",
    headerName: "Transaction ID",
    width: 120,
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    width: 120,
    ...dateTimeColumnDefaultOptions(),
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 70,
    ...currencyColumnDefaultOptions({
      isPence: true,
      currencyKey: "currency",
    }),
  },
  {
    field: "velocity_checks",
    headerName: "Velocity Checks",
    width: 600,
    renderCell: (params) => (
      <Stack spacing={1} sx={{ width: "100%", p: 1 }}>
        {params.row.velocity_checks.map((check, index) => (
          <Paper key={index} variant="outlined" sx={{ p: 1 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3} sx={{ alignSelf: "flex-start" }}>
                <Typography variant="subtitle2">Control</Typography>
                <Typography variant="body2">
                  {check.control.operation || "ALL OPERATIONS"}
                </Typography>
                <Typography variant="body2">{check.control.type}</Typography>
                <Typography variant="body2">{check.control.period}</Typography>
                <Typography variant="body2">{check.control.entity}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ alignSelf: "flex-start" }}>
                <Typography variant="subtitle2">Thresholds</Typography>
                {check.control.aggregate_threshold && (
                  <Typography variant="body2">
                    Aggregate: {check.control.aggregate_threshold}
                  </Typography>
                )}
                <Typography variant="body2">
                  Upper Limit: {check.control.upper_limit}
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ alignSelf: "flex-start" }}>
                <Typography variant="subtitle2">Incident</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    wordBreak: "break-word",
                    overflowWrap: "break-word",
                    maxWidth: "100%",
                  }}
                >
                  Entity ID: {check.incident.entity_id}
                </Typography>
                <Typography variant="body2">
                  Started:{" "}
                  {formatDate(
                    check.incident.started_at,
                    DATE_FORMATS.standardShortDateTime
                  )}
                </Typography>
                <Typography variant="body2">
                  Resolved:{" "}
                  {check.incident.resolved_at
                    ? formatDate(
                        check.incident.resolved_at,
                        DATE_FORMATS.standardShortDateTime
                      )
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {check.review.status === "PENDING" ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      fullWidth
                      onClick={() =>
                        handleReview(
                          params.row.internal_transaction_id,
                          [check],
                          "APPROVED"
                        )
                      }
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="medium"
                      fullWidth
                      onClick={() =>
                        handleReview(
                          params.row.internal_transaction_id,
                          [check],
                          "REJECTED"
                        )
                      }
                    >
                      Reject
                    </Button>
                  </>
                ) : (
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color={
                      check.review.status === "APPROVED"
                        ? "success.main"
                        : "error.main"
                    }
                  >
                    {check.review.status}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Paper>
        ))}
      </Stack>
    ),
  },

  {
    field: "actions",
    headerName: "Actions",
    width: 120,
    renderCell: (params) => (
      <div>
        {params.row.velocity_checks.some(
          (check) => check.review.status === "PENDING"
        ) ? (
          <Stack spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleReview(
                  params.row.internal_transaction_id,
                  params.row.velocity_checks,
                  "APPROVED"
                )
              }
            >
              Approve Transaction
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                handleReview(
                  params.row.internal_transaction_id,
                  params.row.velocity_checks,
                  "REJECTED"
                )
              }
            >
              Reject Transaction
            </Button>
          </Stack>
        ) : (
          <Typography
            variant="body1"
            fontWeight="bold"
            color={
              params.row.velocity_checks.every(
                (check) => check.review.status === "APPROVED"
              )
                ? "success.main"
                : "error.main"
            }
          >
            {params.row.velocity_checks.every(
              (check) => check.review.status === "APPROVED"
            )
              ? "APPROVED"
              : "REJECTED"}
          </Typography>
        )}
      </div>
    ),
  },
]
