import {
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material"

import { useState } from "react"
import { CollapsibleCard, SimpleGrid } from "components"
import { type ReviewStatus } from "api/endpoints"
import { useIncidentColumns } from "./incidentColumns"
import usePaymentVelocityControlIncidents from "./query-hooks/usePaymentVelocityControlIncidents"
import useUpdateIncidentReview from "./query-hooks/useUpdateIncidentReview"
import useUpdateTransactionReview from "./query-hooks/useUpdateTransactionReview"

export default function PaymentVelocityControlIncidentsQueue() {
  const resolveIncident = useUpdateIncidentReview()
  const approveTransaction = useUpdateTransactionReview()

  const [showResolved, setShowResolved] = useState(false)
  const { data, isLoading, error } =
    usePaymentVelocityControlIncidents(showResolved)

  const handleResolveIncident = (
    payment_velocity_incident_id: string,
    transactions: { internal_transaction_id: string }[],
    status: ReviewStatus
  ) => {
    resolveIncident.mutate({
      payment_velocity_incident_id,
      reviews: transactions.map((transaction) => ({
        internal_transaction_id: transaction.internal_transaction_id,
        payment_velocity_incident_id,
        status,
      })),
    })
  }

  const handleReviewTransaction = (
    internal_transaction_id: string,
    payment_velocity_incident_id: string,
    status: ReviewStatus
  ) => {
    approveTransaction.mutate({
      internal_transaction_id,
      reviews: [
        {
          internal_transaction_id,
          payment_velocity_incident_id,
          status,
        },
      ],
    })
  }

  const columns = useIncidentColumns(
    handleResolveIncident,
    handleReviewTransaction
  )

  return (
    <Grid item xs={16}>
      <CollapsibleCard type="main">
        {isLoading ? (
          <Skeleton
            role="progressbar"
            height={600}
            sx={{ transform: "none" }}
          />
        ) : (
          <>
            <br />
            <Stack direction="row" gap={6}>
              <FormControlLabel
                key="actioned_by"
                control={
                  <Checkbox
                    checked={showResolved}
                    onChange={() => setShowResolved(!showResolved)}
                  />
                }
                label="Show Completed"
              />
            </Stack>
            <br />
            <SimpleGrid
              idField="payment_velocity_incident_id"
              initialState={{
                sorting: {
                  sortModel: [{ field: "created_at", sort: "desc" }],
                },
              }}
              rows={data?.incidents || []}
              columns={columns}
              error={error}
              quickFilter
              pagination
              disableColumnFilter={false}
              rowsPerPageOptions={[100, 500, 99999]}
              isLoading={isLoading}
            />
          </>
        )}
      </CollapsibleCard>
    </Grid>
  )
}
