import React, { useState } from "react"
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
} from "@mui/material"
import { CollapsibleCard, SimpleGrid } from "components"
import { useGridQueryParamFilter } from "hooks/useGridQueryParamFilter"
import type { ReviewStatus, VelocityCheck } from "api/endpoints/endpoints"
import { useColumns } from "./columns"
import { usePaymentVelocityControlReviews } from "./query-hooks/usePaymentVelocityControlReviews"
import useUpdateTransactionReview from "./query-hooks/useUpdateTransactionReview"

export default function PaymentVelocityControlsQueue() {
  const [filterModel, onFilterModelChange] = useGridQueryParamFilter()

  const [showCompleted, setShowCompleted] = useState(false)

  const updateReview = useUpdateTransactionReview()

  const handleReview = (
    internal_transaction_id: string,
    velocity_checks: VelocityCheck[],
    status: ReviewStatus
  ) => {
    updateReview.mutate({
      internal_transaction_id,
      reviews: velocity_checks.map((check) => ({
        internal_transaction_id,
        payment_velocity_incident_id:
          check.incident.payment_velocity_incident_id,
        status,
      })),
    })
  }

  const { data, isLoading, error } =
    usePaymentVelocityControlReviews(showCompleted)
  const columns = useColumns(handleReview)

  return (
    <Grid item xs={16}>
      <CollapsibleCard type="main">
        {isLoading ? (
          <Skeleton
            role="progressbar"
            height={600}
            sx={{ transform: "none" }}
          />
        ) : (
          <>
            <br />
            <Stack direction="row" gap={6}>
              <FormControlLabel
                key="actioned_by"
                control={
                  <Checkbox
                    checked={showCompleted}
                    onChange={() => setShowCompleted(!showCompleted)}
                  />
                }
                label="Show Completed"
              />
            </Stack>
            <br />
            <SimpleGrid
              idField="internal_transaction_id"
              initialState={{
                sorting: {
                  sortModel: [{ field: "created_at", sort: "desc" }],
                },
              }}
              rows={data?.transaction_reviews || []}
              columns={columns}
              error={error}
              quickFilter
              pagination
              filterModel={filterModel}
              onFilterModelChange={onFilterModelChange}
              disableColumnFilter={false}
              rowsPerPageOptions={[100, 500, 99999]}
              isLoading={isLoading}
            />
          </>
        )}
      </CollapsibleCard>
    </Grid>
  )
}
