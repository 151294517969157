import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import type { UpdateTransactionReviewPayload } from "api/endpoints/endpoints"
import type { AxiosError } from "axios"
import { useNotification } from "context/notification"

export default function useUpdateTransactionReview(
  options?: Partial<{
    onSuccess: () => void
  }>
) {
  const queryClient = useQueryClient()
  const notify = useNotification()

  return useMutation(
    (payload: UpdateTransactionReviewPayload) => {
      const endpoint =
        endpoints.paymentVelocityControls.updateTransactionsReviews(
          payload.internal_transaction_id
        )
      return endpoint.patch(payload)
    },
    {
      onSuccess: () => {
        notify.success("Transaction review updated successfully")
        options?.onSuccess?.()
        queryClient.invalidateQueries(["paymentVelocityControlIncidents"])
        queryClient.invalidateQueries(["paymentVelocityControlReviews"])
      },
      onError: (e: AxiosError) => {
        const data = e?.response?.data as { message: string }
        notify.error(`Error updating transaction review: ${data?.message}`)
      },
    }
  )
}
